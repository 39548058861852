import React from "react";

import { ReactComponent as WebcasterSvg } from './assets/webcaster.svg';
import { ReactComponent as StreamshoppingSvg } from './assets/streamshopping.svg';
import { ReactComponent as RestreamSvg } from './assets/restream.svg';

import Card, { CardProps } from "../Card";
import styles from './List.module.css';

const projects: CardProps[] = [
  {
    icon: WebcasterSvg,
    title: "Webcaster.pro",
    description: "All-in-one видеоплатформа для бизнеса. Все необходимое для быстрого старта и прибыльной работы с видео в онлайне. Кастомизация, платный доступ, готовая CMS, плеер — прямо из коробки.",
    href: "https://webcaster.pro/?utm_source=projects&utm_campaign=restream"
  },
  {
    icon: StreamshoppingSvg,
    title: "Streamshopping",
    description: "Streamshopping – встраиваемое live commerce решение для организации онлайн-трансляций" ,
    href: "https://streamshopping.ru/?utm_source=projects&utm_campaign=restream"
  },
  {
    icon: RestreamSvg,
    title: "Restream",
    description: "Мощная платформа мультистриминга на любые площадки" ,
    href: "https://restream.su/"
  }
];

const List: React.FC = () => {
  return (
    <div className={styles.projects}>
      <ul className={styles.projects__list}>
        {projects.map(project => (
          <li
            className={styles.projects__list_card}
            key={project.title}
          >
            <Card {...project}/>
          </li>
        ))}
      </ul>

      <div className={styles.projects__author}>
        <a
          href="https://inventos.ru/?utm_source=projects&utm_campaign=restream"
          target="_blank"
          rel="noreferrer"
        >
          Inventos
        </a>
      </div>
    </div>
  );
};

export default List;