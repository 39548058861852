import React from "react";

// * Icons
import { ReactComponent as RestreamSVG } from './assets/restream.svg';
import sprite from './assets/sprite.svg';

import { PlatformIconProps } from './PlatformIcon.interface';

const PlatformIcon: React.FC<PlatformIconProps> = ({ platform }) => {
  // * В спрайтах не работают градиенты, поэтому нужно возвращать иконку "RE" обычной svg(шкой)
  if (platform && platform !== 'Restream.su') {
    return (
      <svg width="100%" height="100%">
        <use href={sprite + `#${platform}`} />
      </svg>
    );
  }

  return <RestreamSVG/>;
};

export default PlatformIcon;