import { FC, useRef, useState } from "react";

// * Icons
import arrow from "./assets/arrow.svg";

// * Components
import { ColorPicker as ColorPalette } from "react-color-palette";

// * Styles
import styles from './ColorPicker.module.css';
import "./ColorPicker.custom.css";
import "react-color-palette/lib/css/styles.css";

// * Hooks
import { useClickOutside } from "../../hooks/useClickOutside";
import { useScreenWidth } from "../../hooks/useScreenWidth";

// * Props
import { ColorPickerProps } from './ColorPicker.interface';

// * Helpers
import cn from "classnames";

const ColorPicker: FC<ColorPickerProps> = ({ color, onChange }) => {
  const pickerRef = useRef<HTMLDivElement>(null);

  // * Hooks
  const [isCoverVisible, setIsCoverVisible] = useState(false);

  // * Detect mobile
  const { isMobile } = useScreenWidth();

  // * Preview color styles
  const previewColorStyle = { background: color.hex };
  const colorValueStyle = { color: color.hex };
  const previewStyle = { borderColor: isCoverVisible ? "var(--border-color-5)" : "var(--border-color-1)" };

  useClickOutside(pickerRef, () => {
    setIsCoverVisible(false);
  });

  return (
    <div className={styles['color-picker']} ref={pickerRef}>
      <div className={styles['color-picker__preview']} style={previewStyle} onClick={() => setIsCoverVisible(!isCoverVisible)}>
        <div className={styles['color-picker__color']} style={previewColorStyle}></div>
        <span className={styles['color-picker__color-value']} style={colorValueStyle}>{ color.hex }</span>
        <img className={cn(isCoverVisible ? styles.opened : styles.closed)} src={arrow} />
      </div>

      { isCoverVisible &&
        <div className={styles['color-picker__cover']}>
          <ColorPalette
            alpha
            width={290}
            height={isMobile ? 120 : 160}
            color={color}
            hideHSV
            onChange={(e) => onChange(e)}
          />
        </div>
      }
    </div>
  );
};

export default ColorPicker;