import React, { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import {
  ChannelError,
  ChannelLatencyType,
  useLazyGetChannelStatusQuery,
  useGetChannelCategoriesQuery,
  useUpdateChannelMutation
} from "../../features/api/apiSlice";
import { Button } from "../Button";
import { ButtonGroup } from "../ButtonGroup";
import { Error } from "../Error";
import { InputField } from "../InputField";
import InputLegend from "../InputLegend";
import { Platform } from "../../features/api/apiSlice";
import { Popup } from "../Popup";
import PopupStructure from "../PopupStructure";
import { PopupTitle } from "../PopupTitle";
import { Checkbox } from "../Checkbox";
import SelectField from "../SelectField";
import { IYoutubeChannelLatencies } from "./EditPlatformPopup.data";
import { EditPlatformPopupProps } from "./EditPlatformPopup.interface";
import cn from "classnames";
import styles from "./EditPlatformPopup.module.css";
import { IOption } from "../SelectField/SelectField.interface";
import { useDebouncedValue } from "../../hooks/useDebouncedValue";
import TextAreaField from "../TextAreaField";

function getDefaultErrors() {
  return { outerTitle: '', outerDescription: '', channelKey: '' };
}

const withoutDescriptionPlatforms: Platform[] = ["twitch", "trovo", "gg"];
const withoutKeyPlatforms: Platform[] = ["gg"];
const withCategoriesPlatforms: Platform[] = ["twitch", "youtube", "gg"];

const EditPlatformPopup: React.FC<EditPlatformPopupProps> = ({
  channel,
  ...popupProps
}) => {
  const [searchName, setSearchName] = useState("");
  const debouncedSearchName = useDebouncedValue(searchName, 500);
  const hasCategories = channel.platform && withCategoriesPlatforms.includes(channel.platform);

  const { data: categories } = useGetChannelCategoriesQuery({
    channelId: channel.id,
    categoryName: debouncedSearchName,
  }, {skip: !hasCategories});

  const [ updateChannel, { isLoading } ] = useUpdateChannelMutation();
  const [ getChannelStatus, { data: channelStatus, isLoading: isChannelStatusLoading } ] = useLazyGetChannelStatusQuery();

  const outerTitleRef = useRef<HTMLTextAreaElement>(null);
  const outerDescriptionRef = useRef<HTMLTextAreaElement>(null);
  const channelKeyRef = useRef<HTMLInputElement>(null);
  const checkboxInputRef = useRef<HTMLInputElement>(null);
  const [selectedCategory, setSelectedCategory] = useState<IOption | null>(() => {
    if (!hasCategories) return null;
    if (!channel.category_id || !channel.category_name) return null;

    return {
      value: channel.category_id,
      label: channel.category_name
    };
  });

  const defaultChannelKey = channel.error === ChannelError.KEY_REQUIRED ? "" : channel.key;

  const [ channelLatency, setChannelLantency ] = useState<ChannelLatencyType | undefined>(channel.latency);
  const [ errorFields, setErrorFields ] = useState(getDefaultErrors());
  const [ errorText, setErrorText ] = useState('');

  const handleInput = useCallback(() => {
    setErrorFields(getDefaultErrors());
    setErrorText('');
  }, []);

  function handleChangeIntegratedChannel(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const outerTitle        = outerTitleRef.current?.value;
    const outerDescription  = outerDescriptionRef.current?.value;
    const channelKey        = channelKeyRef.current?.value;
    const vkWallpost        = checkboxInputRef.current?.checked;

    const isOuterTitleEmpty = outerTitle?.trim() === '';
    const isOuterDescriptionEmpty = !outerDescription || outerDescription.trim() === '';
    const isChannelKeyEmpty = !channelKey || channelKey.trim() === '';

    if (isOuterTitleEmpty || (!withoutDescriptionPlatforms.includes(channel.platform!) && isOuterDescriptionEmpty)) {
      setErrorFields({
        outerTitle: isOuterTitleEmpty ? 'Пустое поле' : '',
        outerDescription: isOuterDescriptionEmpty ? 'Пустое поле' : '',
        channelKey: isChannelKeyEmpty ? 'Пустое поле' : ''
      });
      return setErrorText('Заполните обязательные поля');
    }

    updateChannel({
      id: channel.id,
      outer_title: outerTitle!,
      outer_desc: outerDescription,
      key: channelKey,
      vk_wallpost: vkWallpost,
      category_id: selectedCategory?.value,
      category_name: selectedCategory?.label
    }).then(() => {
      popupProps.onClose();
    });

  }

  const handleChangeLatency = (latency: ChannelLatencyType) => {
    if (channelStatus === "active" || isChannelStatusLoading) return;

    updateChannel({
      id: channel.id,
      latency,
    }).then(() => {
      setChannelLantency(latency);
    });
  };

  useEffect(() => {
    if(channel.platform === "youtube") {
      getChannelStatus({ channelId: channel.id });
    }
  }, [channel]);

  return (
    <Popup {...popupProps} maxWidth="530px">
      <PopupStructure
        headerComponent={
          <PopupTitle onClose={popupProps.onClose}>Редактировать канал</PopupTitle>
        }
        contentComponent={
          <React.Fragment>
            <form
              id="edit-platform-form"
              onSubmit={handleChangeIntegratedChannel}
              className={styles["form"]}
            >
              <InputLegend legend="Название канала">
                <InputField
                  placeholder="Название*"
                  onInput={handleInput}
                  defaultValue={channel.name}
                  readOnly
                />
              </InputLegend>

              <InputLegend legend="Название эфира">
                <TextAreaField
                  isError={Boolean(errorFields.outerTitle)}
                  placeholder="Название эфира*"
                  maxLength={100}
                  onInput={handleInput}
                  ref={outerTitleRef}
                  defaultValue={channel.outer_title}
                />
              </InputLegend>

              {(channel.platform && !withoutDescriptionPlatforms.includes(channel.platform)) && (
                <InputLegend legend="Описание эфира">
                  <TextAreaField
                    isError={Boolean(errorFields.outerDescription)}
                    placeholder="Описание эфира*"
                    maxLength={5000}
                    onInput={handleInput}
                    ref={outerDescriptionRef}
                    defaultValue={channel.outer_desc}
                  />
                </InputLegend>
              )}

              {hasCategories && (
                  <InputLegend legend="Категория">
                    <SelectField
                      placeholder="(Не установлена)"
                      selectedOption={selectedCategory}
                      selectedOptionHandler={setSelectedCategory}
                      value={searchName}
                      setValue={setSearchName}
                      options={categories?.map(category => ({
                        value: category.id,
                        label: category.name,
                        imageSrc: category.box_art_url
                      }))}
                    />
                  </InputLegend>
              )}

              {(channel.platform && withoutKeyPlatforms.includes(channel.platform)) && (
                <InputLegend legend="Ключ трансляции">
                  <InputField
                    isError={Boolean(errorFields.channelKey)}
                    placeholder="Ключ трансляции*"
                    onInput={handleInput}
                    ref={channelKeyRef}
                    defaultValue={defaultChannelKey}
                  />
                </InputLegend>
              )}

              { channel.platform === "vk" &&
                  <div className={styles.checkbox}>
                    <Checkbox
                      value={channel.vk_wallpost}
                      inputRef={checkboxInputRef}
                    >
                      Публиковать эфир на стену
                    </Checkbox>
                  </div>
              }

              {channel.platform === "youtube" && (
                <div className={styles.form__section}>
                  <h6 className={styles.form__heading}>Уровень задержки</h6>

                  <div className={cn(styles.form__latency,{
                      [styles.form__latency_disabled]: channelStatus === "active" || isChannelStatusLoading,
                    })}
                  >
                    {IYoutubeChannelLatencies.map(({ value, label }) => (
                      <div
                        className={cn(styles["form__latency-button"], {
                          [styles["form__latency-button_active"]]: channelLatency === value,
                        })}
                        key={value}
                        onClick={() => handleChangeLatency(value)}
                      >
                        {label}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </form>
            <Error className={styles['error']}>{errorText}</Error>
          </React.Fragment>
        }
        footerComponent={
          <ButtonGroup>
            <Button kind="secondary" type="button" onClick={popupProps.onClose}>Отмена</Button>
            <Button form="edit-platform-form" kind="primary" type="submit">{isLoading ? "Загрузка..." : "Сохранить"}</Button>
          </ButtonGroup>
        }
      />
    </Popup>
  );
};

export default EditPlatformPopup;