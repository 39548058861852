import React, { FC, useState } from 'react';

// * Components
import { Popup, Props } from '../Popup';

// * Local
import PlatformForm from './PlatformForm';
import Platforms from './Platforms';
import { SelectedPlatform } from './AddChannelPopup.interface';
import { PopupTitle } from '../PopupTitle';
import { Button } from '../Button';
import PopupStructure from '../PopupStructure';
import { ButtonGroup } from '../ButtonGroup';
import { useScreenWidth } from '../../hooks/useScreenWidth';

const dictionary: any = {
  "youtube": "Youtube",
  "rutube": "Rutube",
  "twitch": "Twitch",
  "trovo":  "Trovo",
  "nuum": "NUUM",
  "gg": "GoodGame",
  "vk": "Вконтакте",
  "vk_play": "Vk Play",
  "ok": "ОК",
  "telegram": "Telegram",
  "kick": "Kick",
  "boosty": "Boosty",
  "other": "Другое"
};

const AddChannelPopup: FC<Props> = (props) => {
  const { isMobile } = useScreenWidth();
  const [selectedPlatform, setSelectedPlatform] = useState<SelectedPlatform | null>(null);

  let headerComponent,
      contentComponent,
      footerComponent;

  if (selectedPlatform) {
    headerComponent = (
      <PopupTitle
        clarity={isMobile && dictionary[selectedPlatform.platform]}
        onClose={props.onClose}
      >
        Добавить канал
      </PopupTitle>
    );

    contentComponent = (
      <PlatformForm
        id="platform-form"
        selectedPlatform={selectedPlatform}
        clearSelectedPlatform={() => setSelectedPlatform(null)}
        closePopup={props.onClose}
      />
    );

    footerComponent = (
      <ButtonGroup>
        <Button kind="secondary" type="button" onClick={() => setSelectedPlatform(null)}>Назад</Button>
        <Button form='platform-form' kind="primary" type="submit">Создать</Button>
      </ButtonGroup>
    );
  }
  else {
    headerComponent = isMobile && (
      <PopupTitle onClose={props.onClose}>
        Выберите платформу
      </PopupTitle>
    );

    contentComponent = (
      <Platforms setSelectedPlatform={setSelectedPlatform} />
    );

    footerComponent = (
      <Button
        kind="primary"
        onClick={() => setSelectedPlatform({platform: 'other'})}
      >
        Другое
      </Button>
    );
  }

  return (
    <Popup {...props} maxWidth={selectedPlatform ? "530px" : "470px"}>
      <PopupStructure
        headerComponent={headerComponent}
        contentComponent={contentComponent}
        footerComponent={footerComponent}
      />
    </Popup>
  );
};

export default AddChannelPopup;