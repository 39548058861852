import { useGoogleLogin } from "@react-oauth/google";
import { Platform } from "../features/api/apiSlice";
import { useGetBroadcastQuery } from "../features/api/apiSlice";
import { openWindow } from "../helpers/openWindow";

const { REACT_APP_API_ENDPOINT: baseUrl } = process.env;

export const useChannelAuth = (broadcastId: number) => {
  const { refetch: refetchBroadcast } = useGetBroadcastQuery(broadcastId);

  const handleLoginViaTwitch = () => {
    const link = `https://id.twitch.tv/oauth2/authorize?client_id=7ox1sgvgbr410i819aqmo532znxu8p&redirect_uri=${baseUrl}/api/twitch_callback&response_type=code&scope=channel:manage:broadcast%20channel_read%20channel:read:stream_key%20user_read%20chat:read%20chat:edit%20channel:moderate%20whispers:read%20whispers:edit`;
    openWindow(link);
  };

  const handleLoginViaVk = () => {
    const link = `https://oauth.vk.com/authorize?client_id=51410271&display=popup&redirect_uri=${baseUrl}/api/vk_callback&scope=offline,video,groups,wall&response_type=code&v=5.131`;
    openWindow(link);
  };

  const handleLoginViaTrovo = () => {
    const link = `https://open.trovo.live/page/login.html?client_id=54a08bd4649a65154fe1aacfef0c75e4&response_type=code&scope=channel_details_self+channel_update_self+user_details_self+chat_send_self+send_to_my_channel+manage_messages+chat_connect&redirect_uri=${baseUrl}/api/trovo_callback`;
    openWindow(link);
  };

  const handleLoginViaGG = () => {    // clientId для дева dev.restream.su; для прода app.restream.su
    const clientId = String(baseUrl).replace("https://", "");
    const link = `https://goodgame.ru/oauth2/authorize?state=&scope=&response_type=code&approval_prompt=auto&redirect_uri=${baseUrl}/api/goodgame_callback&client_id=${clientId}`;
    openWindow(link);
  };

  const handleLoginViaGoogle = useGoogleLogin({
    scope: 'https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtube.force-ssl',
    flow: 'auth-code',
    onError(error) {
      console.group('%c Google Auth Error', 'color: red');
        console.log(error);
      console.groupEnd();
    },
    onSuccess: (response) => {
      fetch(`${baseUrl}/api/youtube_callback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Requested-With': 'XmlHttpRequest'
        },
        body: `code=${response.code}`
      })
      .then(refetchBroadcast);
    },
  });

  const handleLoginViaPlatform = (platform: Platform) => {
    switch (platform) {
      case "twitch": {
        handleLoginViaTwitch();
        break;
      }

      case "vk": {
        handleLoginViaVk();
        break;
      }

      case "trovo": {
        handleLoginViaTrovo();
        break;
      }

      case "youtube": {
        handleLoginViaGoogle();
        break;
      };

      case "gg": {
        handleLoginViaGG();
        break;
      }
    };
  };

  return {
    handleLoginViaPlatform,
    handleLoginViaTwitch,
    handleLoginViaVk,
    handleLoginViaTrovo,
    handleLoginViaGoogle,
  };
};