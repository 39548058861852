import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChannelError } from "./api/apiSlice";

interface ChannelWithError {
  id: number;
  error: string;
  error_bitrate: number;
}

export interface ILiveStream {
  broadcast_id: number;
  m3u8_url: string;
  status: "online" | "offline";
  error_bitrate: number;
  uptime: number;
  formated_uptime: string;
}


interface StreamState {
  liveStreams: ILiveStream[];
  channelErrors: ChannelWithError[];
}

const initialState: StreamState = {
  liveStreams: [],
  channelErrors: [],
};

export const streamsSlice = createSlice({
  name: "streams",

  initialState,

  reducers: {
    addLiveStream: (state, { payload }: PayloadAction<ILiveStream>) => {
      if (state.liveStreams.some((stream) =>
        stream.broadcast_id === payload.broadcast_id
      )) return;

      state.liveStreams.push(payload);
    },

    removeLiveStream: (state, { payload }: PayloadAction<number>) => {
      state.liveStreams = state.liveStreams.filter((stream) => stream.broadcast_id !== payload);
    },
    addChannelError: (state, action: PayloadAction<ChannelWithError>) => {
      state.channelErrors.push({
        id: action.payload.id,
        error: ChannelError.STREAM_BITRATE_WARNING,
        error_bitrate: action.payload.error_bitrate
      });
    },
    removeChannelError: (state, action: PayloadAction<ChannelWithError['id']>) => {
      state.channelErrors = state.channelErrors.filter(
        (channel) => channel.id !== action.payload
      );
    }
  },
});

export const { addLiveStream, removeLiveStream, addChannelError, removeChannelError} = streamsSlice.actions;
