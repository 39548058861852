import { FC, useState } from 'react';

// * Components
import Platform from './Platform';
import PlatformIcon from '../../PlatformIcon';

// * Hooks
import { useChannelAuth } from '../../../hooks/useChannelAuth';
import { useParams } from 'react-router-dom';

// * Local
import { PlatformsProps } from './Platforms.interface';

// * Styles
import styles from "./Platforms.module.css";
import ClientAgreementModal from '../ClientAgreementPopup/ClientAgreement';

const Platforms: FC<PlatformsProps> = ({ setSelectedPlatform }) => {

  const params = useParams<{ id: string }>();
  const { handleLoginViaPlatform } = useChannelAuth(Number(params.id));
  const [showModal, setShowModal] = useState(false);

  const handlePlatformClick = (platform: string) => {
    if (platform === 'youtube') {
      setShowModal(true);
    } else {
      handleLoginViaPlatform("youtube");
    }
  };

  const handleModalConfirm = () => {
    handleLoginViaPlatform("youtube");
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <ClientAgreementModal
          onConfirm={handleModalConfirm}
          onClose={() => setShowModal(false)} />
      )}
      <div className={styles.platforms}>
        <div className={styles.platforms__platform}>
          <Platform
            icon={<PlatformIcon platform='twitch'/>}
            name="Twitch"
            onClick={() => handleLoginViaPlatform("twitch")}
          />
        </div>

        <div className={styles.platforms__platform}>
          <Platform
            icon={<PlatformIcon platform='vk'/>}
            name="Вконтакте"
            onClick={() => handleLoginViaPlatform("vk")}
          />
        </div>

        <div className={styles.platforms__platform}>
          <Platform
            icon={<PlatformIcon platform='trovo'/>}
            name="Trovo"
            onClick={() => handleLoginViaPlatform("trovo")}
          />
        </div>

        <div className={styles.platforms__platform}>
          <Platform
            icon={<PlatformIcon platform='youtube'/>}
            name="YouTube"
            onClick={() => handlePlatformClick("youtube")}
          />
        </div>

        <div className={styles.platforms__platform}>
          <Platform
            icon={<PlatformIcon platform='rutube'/>}
            name="RuTube"
            onClick={
              () => setSelectedPlatform({
                platform: 'rutube',
                instructionLink: 'https://restream.su/instruction#rec524200748'
              })
            }
          />
        </div>

        <div className={styles.platforms__platform}>
          <Platform
            icon={<PlatformIcon platform='gg'/>}
            name="GoodGame"
            onClick={() => handleLoginViaPlatform("gg")}
          />
        </div>

        <div className={styles.platforms__platform}>
          <Platform
            icon={<PlatformIcon platform='telegram'/>}
            name="Telegram"
            onClick={
              () => setSelectedPlatform({
                platform: 'telegram',
                instructionLink: 'https://restream.su/instruction#rec524082154'
              })
            }
          />
        </div>

        <div className={styles.platforms__platform}>
          <Platform
            icon={<PlatformIcon platform='boosty'/>}
            name="Boosty"
            onClick={
              () => setSelectedPlatform({
                platform: 'boosty',
                instructionLink: 'https://restream.su/instruction#rec529114194'
              })
            }
          />
        </div>

        <div className={styles.platforms__platform}>
          <Platform
            icon={<PlatformIcon platform='nuum'/>}
            name="NUUM"
            onClick={
              () => setSelectedPlatform({
                platform: 'nuum',
                instructionLink: 'https://restream.su/instruction#rec529114172'
              })
            }
          />
        </div>

        <div className={styles.platforms__platform}>
          <Platform
            icon={<PlatformIcon platform='vk_play'/>}
            name="VKPlay"
            onClick={
              () => setSelectedPlatform({
                platform: 'vk_play',
                instructionLink: 'https://restream.su/instruction#rec533224727'
              })
            }
          />
        </div>

        <div className={styles.platforms__platform}>
          <Platform
            icon={<PlatformIcon platform='kick'/>}
            name="Kick"
            onClick={
              () => setSelectedPlatform({
                platform: 'kick',
                instructionLink: 'https://restream.su/instruction#rec529115630'
              })
            }
          />
        </div>

        <div className={styles.platforms__platform}>
          <Platform
            icon={<PlatformIcon platform='ok'/>}
            name="ОК"
            onClick={
              () => setSelectedPlatform({
                platform: 'ok',
                instructionLink: 'https://restream.su/instruction#rec524205209'
              })
            }
          />
        </div>
      </div>
    </>
  );
};

export default Platforms;