import { FC } from 'react';

// * Components
import { Link } from '../../Link';

// * Props
import { UserInfoProps } from './UserInfo.interface';

// * Hooks
import { useScreenWidth } from '../../../hooks/useScreenWidth';

// * Helpers
import cn from "classnames";
import { formatDate } from '../../../helpers/formatDate';

// * Styles
import styles from "./UserInfo.module.css";
import { Button } from '../../Button';

const UserInfo: FC<UserInfoProps> = ({ user }) => {
  const { isMobile } = useScreenWidth();

  return (
    <div className={styles['info']}>

      { !isMobile &&
          <div className={styles['info__row']}>
            <span className={styles['info__field-title']}>Дата регистрации:</span>
            <span>{formatDate(user?.registration_date)}</span>
          </div>
      }

      <div className={styles['info__row']}>
        <span className={styles['info__field-title']}>Тариф:</span>
        <span className={styles['info__tariff']}>{user?.tariff.name} <Link to="/tariffs">Сменить тариф</Link></span>
      </div>

      <div className={styles['info__row']}>
        <span className={styles['info__field-title']}>Дата оплаты:</span>
        <span>{formatDate(user?.tariff.start_at)}</span>
      </div>

      <div className={styles['info__row']}>
        <span className={styles['info__field-title']}>Дата окончания:</span>
        <span>{user?.tariff.end_at ? formatDate(user?.tariff.end_at) : "Не ограничена"}</span>
      </div>

      <div className={styles['info__row']}>
        <span className={styles['info__field-title']}>Стоимость:</span>
        <span>{user?.tariff?.price ? `${user.tariff.price} р.` : 'Бесплатно'}</span>
      </div>

      <span className={cn(styles['info__full-width'])}>
          { isMobile
            ? <Link to={'/payment-history'}><Button kind="primary">История платежей</Button></Link>
            : <Link to={'/payment-history'}>Посмотреть историю платежей</Link>
          }
      </span>
    </div>
  );
};

export default UserInfo;